
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { AiApi } from "@evercam/shared/api/aiApi"
import BoundingBox from "@evercam/shared/components/BoundingBox"
import { toTitleCase } from "@evercam/shared/utils"

export default {
  name: "EvercamObjectDetection",
  components: {
    BoundingBox,
    EvercamLoadingAnimation,
  },
  props: {
    image: {
      type: [String, HTMLImageElement],
      default: "",
    },
    timestamp: {
      type: String,
      default: "",
    },
    isEdgeVideo: {
      type: Boolean,
      default: false,
    },
    cameraId: {
      type: [String, null],
      default: null,
    },
    sidebarHeight: {
      type: String,
      default: "auto",
    },
  },
  data() {
    return {
      isProcessing: false,
      items: [],
      focusedItems: {},
      resultsPanel: [0],
      modelVersion: "",
      filteredTree: [],
      filteredBoxes: [],
      openIds: [],
      headers: [
        {
          text: "Detection",
          value: "detection",
        },
        {
          text: "Count",
          value: "count",
        },
      ],
      detectionThresholdLimit: 30,
      isImageLoading: false,
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (item) => item.score >= this.detectionThresholdLimit
      )
    },
    isLoading() {
      return this.isProcessing || this.isImageLoading
    },
  },
  watch: {
    image: {
      immediate: true,
      handler(image) {
        if (image?.includes("unavailable.jpg")) {
          this.resetBrainToolData()

          return
        }
        if (!image || (this.isEdgeVideo && !image.includes("data:image"))) {
          return
        }
        this.fetchObjectDetectionsResults()
      },
    },
    isLoading(val) {
      this.$emit("is-processing", val)
    },
    filteredItems(arr) {
      this.filteredTree = arr.reduce((acc, item) => {
        const objectIndex = acc.findIndex(
          (obj) => obj.name?.split(" ").slice(0, -1).join("") === item.label
        )
        const object = acc[objectIndex]

        if (objectIndex > -1) {
          acc[objectIndex] = {
            id: item.label,
            name: `${item.label} (${object.count + 1})`,
            icon: item.label,
            count: object.count + 1,
            children: [
              ...object.children,
              {
                id: `${item.label}-${object.count + 1}`,
                name: `${item.label} ${object.count + 1}`,
                box: item,
              },
            ],
          }

          return acc
        }

        return [
          ...acc,
          {
            id: item.label,
            name: `${item.label} (1)`,
            icon: item.label,
            count: 1,
            children: [
              {
                id: `${item.label}-1`,
                name: `${item.label} 1`,
                box: item,
              },
            ],
          },
        ]
      }, [])

      this.filteredBoxes = this.filteredTree.reduce(
        (acc, item) => [...acc, ...item.children],
        []
      )
    },
  },
  methods: {
    toTitleCase,
    async fetchObjectDetectionsResults() {
      this.isProcessing = true
      this.items = []
      this.focusedItems = {}

      if (!this.image) {
        this.isProcessing = false

        return
      }

      try {
        const payload = {
          cameraExid: this.cameraId,
          ...(this.isEdgeVideo
            ? { snapshotUrl: this.image }
            : { timestamp: this.timestamp }),
        }
        const data = await AiApi.brainTool.getObjectDetectionsResults(payload)
        this.items = data?.detections?.detections.sort((a, b) =>
          Number(a.score) > Number(b.score) ? -1 : 1
        )
        this.modelVersion = data?.modelVersion
      } catch (error) {
        if (
          error.response.data?.details === "Resource not found" ||
          error.response.status === 404
        ) {
          this.$notifications.error({
            text: this.$t("content.fetch_resource_failed", {
              resource: "brainTool results",
            }),
          })
        } else {
          this.$notifications.error({
            text: this.$t("content.fetch_resource_failed", {
              resource: "brainTool results",
            }),
            error,
            notifyOnCliq: true,
            notifyParams: {
              ERROR: error,
              REQUEST_PAYLOAD: {
                cameraId: this.cameraId,
                timestamp: this.timestamp,
              },
              FEATURE: "BrainTool",
            },
          })
        }
      } finally {
        this.isProcessing = false
        this.isImageLoading = false
      }
    },
    setActiveTreeviewItemStyle(index, focused, isBoxHovered) {
      this.$nextTick(() => {
        const treeviewItem = document
          .querySelector(`.${index}`)
          ?.closest(".v-treeview-node__root")

        if (!treeviewItem) {
          return
        }

        if (focused) {
          treeviewItem.classList.add("v-treeview-node__root--active")
          if (isBoxHovered) {
            treeviewItem.scrollIntoView()
          }
        } else {
          treeviewItem.classList.remove("v-treeview-node__root--active")
        }
      })
    },
    isFocused(index) {
      return this.focusedItems[index]
    },
    setFocused(index, focused, isBoxHovered) {
      this.focusedItems = {
        ...this.focusedItems,
        [index]: focused,
      }

      this.openIds = [...this.openIds, index.split("-").slice(0, -1).join("-")]
      this.setActiveTreeviewItemStyle(index, focused, isBoxHovered)
    },
    resetBrainToolData() {
      this.items = []
    },
    getActiveNodes(nodes) {
      this.openIds = nodes
    },
  },
}
