
import Vue from "vue"

import EvercamVideoPlayer from "@evercam/shared/components/EvercamVideoPlayer"
import EvercamImagePlayer from "@evercam/shared/components/EvercamImagePlayer"
import PlayerProgressBar from "@evercam/shared/components/imagePlayer/PlayerProgressBar"
import PlayerActions from "@evercam/shared/components/imagePlayer/PlayerActions"
import EdgeVideoThumbnailPreview from "@evercam/shared/components/imagePlayer/EdgeVideoThumbnailPreview"
import { ImageQuality } from "@evercam/shared/types/imagePlayer"
import { CameraStatus } from "@evercam/shared/types/camera"
import { matchEventsWithClosestPlayerFrameIndex } from "@evercam/shared/utils"

import type { Camera, NvrConfig } from "@evercam/shared/types/camera"
import type { Snapshot } from "@evercam/shared/types/recording"
import { AnalyticsEvent, PlayerMode } from "@evercam/shared/types"
import type { GateReportEvent } from "@evercam/shared/types/gateReport"
import type { PropType } from "vue"
import type {
  Frame,
  MatchedSnapshotEvent,
  SnapshotEvent,
} from "@evercam/shared/types/imagePlayer"
import { EZoomSlider, inactivityListener } from "@evercam/ui"
export default Vue.extend({
  name: "EvercamPlayer",
  components: {
    PlayerProgressBar,
    PlayerActions,
    EdgeVideoThumbnailPreview,
    EZoomSlider,
    EvercamVideoPlayer,
    EvercamImagePlayer,
  },
  mixins: [inactivityListener],
  props: {
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    nvrConfig: {
      type: Object as PropType<NvrConfig>,
      default: () => ({}),
    },
    selectedTimestamp: {
      type: [String, Date, Number],
      default: "",
    },
    start: {
      type: [String, Date, Number],
      default: undefined,
    },
    end: {
      type: [Date, String, Number],
      default: undefined,
    },
    authToken: {
      type: String,
      default: "",
    },
    isEdgeVideo: {
      type: Boolean,
      default: false,
    },
    showPlayerModeToggle: {
      type: Boolean,
      default: false,
    },
    hasLive: {
      type: Boolean,
      default: false,
    },
    edgeStreamingToken: {
      type: String,
      default: "",
    },
    showBif: {
      type: Boolean,
      default: false,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    //💡: this props will tell us if we are on live view or not <> isLive = !fetchInitialSnapshot
    fetchInitialSnapshots: {
      type: Boolean,
      default: true,
    },
    events: {
      type: Array as PropType<SnapshotEvent<GateReportEvent>[]>,
      default: () => [],
    },
    initialSnapshotQuality: {
      type: [String, Number],
      default: "auto",
    },
    showSnapshotQuality: {
      type: Boolean,
      default: true,
    },
    timePerFrame: {
      type: Number,
      default: 250,
    },
    withControls: {
      type: Boolean,
      default: true,
    },
    disablePlayButton: {
      type: Boolean,
      default: false,
    },
    playOnClick: {
      type: Boolean,
      default: true,
    },
    withOverlay: {
      type: Boolean,
      default: false,
    },
    isVideoDisabled: {
      type: Boolean,
      default: false,
    },
    isWebRtcDisabled: {
      type: Boolean,
      default: false,
    },
    withPlayerActions: {
      type: Boolean,
      default: true,
    },
    withPlayerProgressBar: {
      type: Boolean,
      default: true,
    },
    withZoomSlider: {
      type: Boolean,
      default: true,
    },
    customRefreshRate: {
      type: [Number],
      default: undefined,
    },
  },
  data() {
    return {
      snapshots: [] as Snapshot[],
      image: null as HTMLImageElement | null,
      isVideoError: false,
      isFetchingSnapshots: false,
      frames: [] as Frame[],
      frameIndex: 0,
      infoText: {
        index: "",
        label: "",
      },
      userSelectedTimestamp: 0 as string | number,
      preloadedFrames: [] as number[],
      preloadingQueueId: 0,
      selectedSnapshotQuality: this.initialSnapshotQuality,
      isLoading: true,
      timePerFrameValue: this.timePerFrame,
      isFullscreen: false,
      cacheEventsMatching: false,
      previousEventsTimestamps: [],
    }
  },
  computed: {
    playerDimensions(): { height: string; width: string } {
      if (this.isFullscreen && !this.$device.isIos) {
        return {
          height: `${window.innerHeight}px`,
          width: "100%",
        }
      } else {
        return {
          height: `${this.$attrs.height}px`,
          width: `${this.$attrs.width}px`,
        }
      }
    },
    cameraTimezone(): string {
      return this.camera?.timezone || this.timezone
    },
    cameraExid(): string {
      return this.camera?.exid
    },
    isVideo(): boolean {
      if (this.isVideoDisabled || this.isVideoError) {
        return false
      }

      if (this.isLive) {
        return (
          this.isWebrtcEnabled || this.isVideoStreamEnabled || this.isEdgeVideo
        )
      }

      if (this.isWebrtcEnabled) {
        return !this.fetchInitialSnapshots
      }

      return this.isEdgeVideo
    },
    isEdgeVideoEnabled() {
      return this.isEdgeVideo && !this.isVideoError
    },
    isWebrtcEnabled(): boolean {
      return (
        this.$permissions.camera?.has.webrtc() &&
        this.isCameraOnline &&
        !this.$device.isIos &&
        !this.isWebRtcDisabled
      )
    },
    isVideoStreamEnabled(): boolean {
      return (
        this.$permissions.camera?.has.videoStream() &&
        this.hlsUrl &&
        !this.isWebrtcEnabled &&
        !this.$device.isFirefox
      )
    },
    hlsUrl(): string {
      return this.camera?.proxyUrl?.hls
    },
    isLastFrame(): boolean {
      return this.frameIndex === this.frames.length - 1
    },
    matchedSnapshotEvents(): MatchedSnapshotEvent<GateReportEvent>[] {
      if (this.cacheEventsMatching && this.previouslyMatchedEvents) {
        return this.events.map((e) => {
          const cachedMatchedEvent =
            this.previouslyMatchedEvents.find(
              (event) =>
                (event.id && e.id && event.id === e.id) ||
                (event.tempId && e.tempId && event.tempId === e.tempId)
            ) || {}

          return {
            ...e,
            ...cachedMatchedEvent,
          }
        })
      } else {
        return matchEventsWithClosestPlayerFrameIndex(this.events, this.frames)
      }
    },
    isCameraOnline(): boolean {
      return this.camera?.status === CameraStatus.Online
    },
  },
  watch: {
    withOverlay: {
      immediate: true,
      handler(value) {
        if (value) {
          this.isFetchingSnapshots = false
          this.isLoading = false
        }
      },
    },
    isVideo: {
      async handler(value) {
        this.$emit("toggle-video-mode", value)
      },
      immediate: true,
    },
    // The following avoids recomputing the matched events whenever an event object changes
    // TODO Remove after migrating to Vue 3, and use v-memo instead
    matchedSnapshotEvents(newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
        return
      }
      this.cacheEventsMatching = true
      this.previouslyMatchedEvents = newVal.map((e) => {
        return {
          id: e.id,
          tempId: e.tempId,
          frameIndex: e.frameIndex,
          snapshotTimestamp: e.snapshotTimestamp,
        }
      })
    },
    camera() {
      if (!this.hasLive) {
        this.updateLive(false)
      }
    },
    frameIndex: {
      immediate: true,
      handler(index, previousIndex) {
        if (
          (index <= 0 && previousIndex > 0) ||
          index === this.frames.length - 1
        ) {
          this.$emit("snapshot-edge-reached")
        }

        if (index === previousIndex) {
          return
        }

        this.updateToLatestFrameOnLive()
        if (index && Math.abs(index - previousIndex) > 1 && !this.withOverlay) {
          this.isLoading = true
        }
      },
    },
    frames: {
      immediate: true,
      handler() {
        this.updateToLatestFrameOnLive()
        this.cacheEventsMatching = false
      },
    },
    events(newVal) {
      const eventsTimestamps = newVal.map((e) => e.eventTime)
      this.cacheEventsMatching =
        JSON.stringify(this.previousEventsTimestamps) ===
        JSON.stringify(eventsTimestamps)
      this.previousEventsTimestamps = eventsTimestamps
    },
    isLive: {
      immediate: true,
      async handler(value: boolean) {
        this.updateToLatestFrameOnLive()
        if (value) {
          this.isLoading = true
          this.updatePlayback(true)
        } else {
          this.updateLive(false)
        }
      },
    },
    selectedTimestamp: {
      immediate: true,
      handler(t: string | Date) {
        this.$emit("timestamp-change", t)
        this.resetUserSelectedTimestampOnHourChange(t)
      },
    },
    isLoading: {
      immediate: true,
      handler(val) {
        this.$emit("loading", val)
      },
    },
  },
  mounted() {
    this.$addEventListener("fullscreenchange", this.setFullscreen)
    this.$addEventListener("webkitfullscreenchange", this.setFullscreen)
    this.$addEventListener("mozfullscreenchange", this.setFullscreen)
    this.$addEventListener("MSFullscreenChange", this.setFullscreen)
  },
  methods: {
    updateFrameIndex(index: number) {
      this.frameIndex = index
    },
    setFullscreen() {
      this.isFullscreen =
        document.fullscreenElement ||
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement
    },
    updatePlayback(value: boolean) {
      this.$emit("update-playback", value)
    },
    updateLive(value: boolean) {
      this.$emit("update-live", value)
    },
    zoom(direction: number) {
      const player = this.$refs?.player?.$refs?.player as any
      const zoomableImage = player.$refs?.zoomableImage
      zoomableImage.zoom(direction)
      this.$root.$emit("analytics-event", {
        eventId:
          direction > 0
            ? AnalyticsEvent.PlayerZoomOut
            : AnalyticsEvent.PlayerZoomIn,
        params: { zoomLevel: zoomableImage.zoomLevel },
      })
    },
    onOverlayClick() {
      if (this.playOnClick) {
        this.updatePlayback(!this.isPlaying)
      }
    },
    onSnapshotsFetched(snapshots: Snapshot[]) {
      this.snapshots = snapshots
      this.$emit("snapshots-fetched", snapshots)
      this.isFetchingSnapshots = false
    },
    updateToLatestFrameOnLive() {
      if (!this.isLive) {
        return
      }
      this.frameIndex = this.frames.length > 0 ? this.frames.length - 1 : 0
    },
    toggleCurrentPlayer() {
      if (this.isLive || !this.fetchInitialSnapshots) {
        this.toggleLive()
      } else {
        this.updatePlayback(!this.isPlaying)
      }
    },
    toggleLive() {
      this.updateLive(!this.isPlaying)
      this.updatePlayback(!this.isPlaying)
    },
    updateInfoText() {
      const frameOrder = `${Math.min(
        this.frameIndex + 1,
        this.frames.length
      )} ${this.$t("content.of")} ${this.frames.length}`
      const dateTime = this.frames[Math.max(0, this.frameIndex)]?.label
      if (!dateTime) {
        return
      }
      const formattedDateTime = this.$moment(
        dateTime,
        "DD/MM/YYYY HH:mm:ss"
      ).format("Do MMM, YYYY | HH:mm:ss")
      let infoTextLabel = this.$device.isMobile
        ? formattedDateTime
        : `${this.$t("content.recordings.time")}: ${formattedDateTime}`
      this.infoText = {
        index: `${this.$t("content.recordings.frame")}: ${frameOrder}`,
        label: infoTextLabel || "",
      }
    },
    onUserSelectedFrameIndex(frameIndex: number) {
      this.updateLive(false)
      this.userSelectedTimestamp = this.frames[frameIndex].timestamp
      this.frameIndex = frameIndex
      this.$emit("timestamp-change", this.frames[frameIndex].timestamp)
    },
    onImageLoad(e: any) {
      this.image = e as HTMLImageElement
      this.updateInfoText()
      this.isLoading = false
    },
    onImageError() {
      this.isLoading = false
    },
    onSnapshotQualityChange(q: ImageQuality) {
      this.selectedSnapshotQuality = q
    },
    cancelPreviousPreloading() {
      if (!this.isVideo) {
        this.preloadingQueueId += 1
      }
    },
    onVideoError(e: Error) {
      this.isVideoError = true
      this.frameIndex = 0
      this.$emit("error", e)
      this.$emit("toggle-video-mode", false)
    },
    resetUserSelectedTimestampOnHourChange(t: string | Date) {
      const selectedTime = this.$moment(t)
      const userSelectedTime = this.$moment(this.userSelectedTimestamp)
      if (
        Math.abs(selectedTime.diff(userSelectedTime, "hours")) > 1 ||
        selectedTime.hours() !== userSelectedTime.hours()
      ) {
        this.userSelectedTimestamp = this.selectedTimestamp
      }
    },
    onPlayerModeUpdate(value: string) {
      const isVideoMode = value === PlayerMode.Video
      if (isVideoMode) {
        this.isVideoError = false
      }
      this.$emit("toggle-video-mode", isVideoMode)
    },
  },
})
